import {cva, cx} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const HeadingStyles = cx(
  '[&_span]:font-medium',
  '[&_span]:font-aktivgroteskextended',
  '[&_span]:tracking-[-.02em] [&_span]:tablet:tracking-[-.01em]',
  '[&_span]:text-[34px] [&_span]:leading-[38px]',
  '[&_span]:tablet:text-[32px] [&_span]:tablet:leading-[40px]',
  '[&_span]:desktop:text-[40px] [&_span]:desktop:leading-[48px]',
  '[&_p]:pt-4 [&_p]:pb-6',
  '[&_p]:font-normal [&_p]:tracking-[-.02em]',
  '[&_p]:text-[18px] [&_p]:leading-[26px]',
  '[&_p]:tablet:text-xl',
  '[&_p]:desktop:text-[22px] [&_p]:desktop:leading-8',
  '[&_p]:max-w-prose [&_p]:mx-auto',
);

export const EnterpriseHeadingStyles = cx(
  '[&_span]:tracking-[-.68px] [&_span]:tablet:tracking-[-.8px] [&_span]:desktop:tracking-[-.4px]',
  '[&_span]:text-[34px] [&_span]:leading-[38px]',
  '[&_span]:tablet:text-[40px] [&_span]:tablet:leading-[48px]',
  '[&_p]:pt-4 [&_p]:pb-6',
  '[&_p]:text-[18px] [&_p]:leading-[26px] [&_p]:tracking-[-.36px] [&_p]:tablet:text-[22px] [&_p]:tablet:leading-8 [&_p]:tablet:tracking-[-.44px]',
);

export const CtaStyles = cva('[&_p]:text-xs [&_p]:pt-1', {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: [
        '[&_button[type="submit"]]:bg-[#390279] [&_button[type="submit"]:hover]:bg-[#390279] [&_button[type="submit"]:focus]:bg-[#390279] [&_button[type="submit"]:active]:bg-[#390279]',
        '[&_button[type="submit"]]:text-white',
      ],
      [BLOG_PAGE_TYPE.ENTERPRISE]: [
        '[&_button[type="submit"]]:btn-primary [&_button[type="submit"]]:font-faktum [&_button[type="submit"]]:mt-0',
        '[&_form>div+div]:hidden [&_form>div>input+div]:inline-block',
      ],
      [BLOG_PAGE_TYPE.ENGINEERING]: '',
    },
  },
});
